import React from "react"
import { graphql, Link } from "gatsby"

import BlogFeed from "../components/blog-feed";

import * as styles from "./blog-list.module.scss"

const BlogListTemplatePage = ({ data, pageContext }) => {
  const isFirst = pageContext.currentPage === 1
  const isLast = pageContext.currentPage === pageContext.numPages
  const prevPage = pageContext.currentPage - 1 === 1 ? "" : (pageContext.currentPage - 1).toString()
  const nextPage = (pageContext.currentPage + 1).toString()
  return (
    <div>
      <BlogFeed posts={data.allMarkdownRemark.edges} more={false}/>
      <div className={`container ${styles.more}`}>
        <div className="row">
          <div className="col-6 text-end">
            {!isLast && 
              <Link to={`/blog/${nextPage}`} className="btn">← Précédent</Link>
            }
          </div>
          <div className="col-6 text-start">
            {!isFirst && 
              <Link to={`/blog/${prevPage}`} className="btn">Suivant →</Link>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/_posts/.*.md$/"}}
      limit: $limit
      skip: $skip
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "D MMMM YYYY", locale: "FR-fr")
            excerpt
            image {
              id
              childImageSharp {
                gatsbyImageData (
                  aspectRatio: 1.5
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;


export default BlogListTemplatePage
